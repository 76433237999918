// 将数字强制保留两位小数
export function mustTwoDecimal (number) {
  if (number) {
    var num = number.toString()
    var index = num.indexOf('.')
    if (index <= 0) {
      num += '.'
      index = num.length - 1
    }
    while ((index + 3) !== num.length) {
      num += '0'
    }
    return num
  }
}

// 时间戳转化
export function timeConversion (time1) {
  if (time1) {
    const time = new Date(time1 * 1000)
    const year = time.getFullYear()
    const month = (time.getMonth() + 1).toString().padStart(2, '0')
    const date = (time.getDate()).toString().padStart(2, '0')
    const hours = (time.getHours()).toString().padStart(2, '0')
    const minute = (time.getMinutes()).toString().padStart(2, '0')
    const second = (time.getSeconds()).toString().padStart(2, '0')

    return year + '-' + month + '-' + date + ' ' + hours + ':' + minute + ':' + second
  }
}

// 手机号码中间隐藏函数
export function hideCenterPhone (phone) {
  if (phone) {
    const phoneStart = phone.substring(0, 3)
    const phoneEnd = phone.substring(7, 11)
    return phoneStart + '****' + phoneEnd
  }
}

// 名字开头隐藏函数
export function hideStartName (name) {
  if (name) {
    const nameEnd = name.substring(1)
    return '*' + nameEnd
  }
}
