<template>
  <div class="item">
    <div class="title" :class="{'active': index === noticeListItem && currentPage === 1}">
      <span v-if="index === noticeListItem && currentPage === 1" class="new">NEW</span>
      <img v-else class="gonggao" src="~assets/icons/gonggao.png">
      {{noticeListItem.title}}
    </div>
    <div class="info">
      <div class="info-content" v-html="noticeListItem.content"></div>
    </div>
    <div class="addtime">
      <span>发布时间：{{createtime}}</span><span class="sdf">{{$store.getters.getSiteInfo.name}}</span>
    </div>
  </div>
</template>

<script>
import { timeConversion } from 'utils/utils'

export default {
  props: {
    index: Object,
    noticeListItem: Object,
    currentPage: Number
  },
  computed: {
    createtime () {
      return timeConversion(this.noticeListItem.createtime)
    }
  }
}
</script>

<style scoped src="styles/views/notice/noticelistitem.css">
</style>
